import { useEffect } from 'react';

import ReactGA from 'react-ga4';

import Home from './Home';

const App = () => {
	useEffect(() => {
		ReactGA.initialize('G-P6QH5P4WMY');
		ReactGA.send({
			hitType: 'pageview',
			page: '/app',
			title: 'Initial page loaded',
		});
	}, []);

	return (
		<>
			<Home />
		</>
	);
};

export default App;
