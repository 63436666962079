import { Button } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled('div')`
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Center = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;

export const ContactButton = styled(Button)`
	&.MuiButton-root {
		color: black;
	}
`;
