import ReactGA from 'react-ga4';
import { Link, Typography } from '@mui/material';
import { Center, ContactButton, Wrapper } from './styles';

const Home = () => {
	const handleContactClick = () => {
		ReactGA.initialize('G-P6QH5P4WMY');
		ReactGA.send({
			hitType: 'click',
			page: '/home',
			title: 'Clicked on Contact',
		});
	};

	return (
		<Wrapper>
			<Center>
				<Typography variant='h2'>GRCHMRX</Typography>
				<Link
					href='mailto: the.grchmrx@gmail.com'
					target='_blank'
					rel='noopener'>
					<ContactButton onClick={handleContactClick}>Contact</ContactButton>
				</Link>
			</Center>
		</Wrapper>
	);
};

export default Home;
